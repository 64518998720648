.earn-view {
  background: var(--color-theme-white-dark);
  margin: 0;
}
.earn-view .container {
  display: flex;
  flex-direction: /*row*/ column-reverse;
   align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2rem;
}
.earn-view .earn-text {
  width: 100%;
  margin-top: 1.5rem;
  line-height: 1.5;
}
.earn-view .earn-text h1 {
  font-size: 35px;
  padding: 1rem 0;
  color: var(--color-main)
}
.earn-view .earn-text h5 {
  margin-bottom: 0.5rem;
}

.earn-view .earn-text p {
  line-height: 1.5;
  padding: 1rem 0;
  color: var(--color-main);
  font-size: 16px;
}
.earn-view .earn-image img{
  width: 100%;
}
@media (min-width: 48rem) {
  .earn-view .container {
    flex-direction: row;
    justify-content: center;
  }
  .earn-view .earn-text {
      margin-top: 0;
      width: 50%;
  }
  .earn-view .earn-text p {
    width: 80%;
    font-size: 20px;
    
  }
  .earn-view .earn-text h1 {
    font-size: 3rem;
   
  }
}
