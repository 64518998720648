.unique__feature {
  background: hsla(33, 83%, 61%, 1);

  background: linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -moz-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
  padding: 54px 0;
}

.unique__feature-heading {
  padding: 2rem 0;
  text-align: center;
}
.unique__feature-heading:after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.unique__feature-heading h1 {
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-theme-white);
}
.unique__feature-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.unique__feature-cards__card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: justify;
  font-size: 16px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  background: var(--color-theme-white);
  color: var(--color-main);
  border-radius: 10px;
  transition: all 600ms ease !important;
  overflow: hidden;
}

.unique__feature-card-content {
  padding: 20px 30px;
  overflow: hidden;
}
.unique__feature-cards__card img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.unique__feature-cards__card:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.281);
  transform: translateY(-5px);
  outline: none;
}

.unique__feature-cards__card .title {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
}

.unique__feature-cards__card h3 {
  margin: 1rem 0;
  text-align: center;
}

@media (min-width: 60rem) {
  .unique__feature-cards {
    flex-direction: row;
  }
  .unique__feature-cards__card {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    width: 350px !important;
  }
  .unique__feature .unique__feature-heading h1 {
    font-size: 2.5rem;
  }
}
