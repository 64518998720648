.nav-wrapper {
  background: transparent;

  width: 100%;
}

.nav-wrapper .nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1rem 1.2rem;
  transition: all 2000ms ease-in-out !important;
}
.nav-wrapper .nav .nav-brand a img {
  height: 60px;
  display: none;
}

.nav-wrapper .nav .nav-menu-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: hsla(33, 83%, 61%, 1);

  background: linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -moz-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
  bottom: 100%;
  top: 0;
  z-index: 10;
  right: 0;
  list-style-type: none;
  align-items: center;
  transform: translateX(-800px);
  transition: all 0.5s;
}
.nav-wrapper .nav .nav-menu-group.mobile {
  transform: translateX(0px);
}

.nav-wrapper .nav .nav-menu-group .nav-menu-list {
  margin-right: 1.66rem;
  font-size: 1rem;
  font-weight: 400;
}

.bars {
  cursor: pointer;
  z-index: 999;
}
.bars.clicked .line:nth-child(2) {
  display: none;
}
.bars.clicked .line:nth-child(1) {
  transform: rotate(140deg) translateY(-8px);
}
.bars.clicked .line:nth-child(3) {
  transform: rotate(-140deg) translateY(10px);
}
.bars .line {
  transition: all 500ms;
  height: 3px;
  width: 45px;
  display: block;
  background: #fff;
  margin-bottom: 0.78rem;
  border-radius: 5px;
}
@media (min-width: 60rem) {
  .nav-wrapper {
    background-position: top;
  }
  .nav-wrapper .nav {
    padding: 1rem;
  }
  .nav-wrapper .nav .nav-menu-group {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    position: static;
    height: auto;
    width: auto;
    background: none;
    align-items: center;
    transform: translateX(0px);
    transition: all 0.6s;
  }
  .nav-wrapper .nav .nav-menu-group a {
    font-size: 18px;
    margin-bottom: 0;
  }
  .bars {
    display: none;
  }
}
