@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
 outline: none;
 transition: all 0.6s ease;
 /* border: var(--color-main) 2px solid; */
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion_ {
  background-color: var(--color-theme-white-dark);
  color: var(--color-main);
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.5s ease;
  box-shadow: 1px 1px 166px -88px rgba(0,0,0,0.52);
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: var(--color-theme-white);
  outline: none;
}

/* Style the accordion content title */
.accordion-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion-icon {
  margin-left: auto;
  transition: transform 0.5s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
