.ad-video {
    background: var(--color-theme-white);
    padding: 2rem 0;
}
.ad-video .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  padding: 1rem 2rem;
  /* height: 100vh; */
}
.ad-video .container .player-image {
  width: 40%;
}
.ad-video .container .player-image img {
  width: 100%;
}
.react-player {
  filter: drop-shadow(3px 10px 9px #d30700a4);
  width: 343px !important;
  height: 250px !important;
  border-radius: 5px;
}
.react-player video {
  width: 100%;
  height: 100% !important;
  /* display: none; */
}
@media (min-width: 48rem) {
  .ad-video .container {
    height: auto;
    flex-direction: row;
    gap: 5rem;
  }
  .ad-video .container .player-image {
    width: 10%;
  }
  .react-player {
    width: 343px !important;
  }
  .react-player video {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
