.faq-section{
  padding: 2rem 0;
  /* background: whitesmoke; */
  background: hsla(33, 83%, 61%, 1);

background: linear-gradient(315deg, hsla(33, 83%, 61%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);

background: -moz-linear-gradient(315deg, hsla(33, 83%, 61%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);

background: -webkit-linear-gradient(315deg, hsla(33, 83%, 61%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
}
.faq-section .container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faq-section .container h1{
  padding: 2rem 0;
  color: var(--color-theme-white);
  font-size: 30px;
  text-align: center
}
.faq-section .accordion-group .accordion-text{
  line-height: 1.5
}
.faq-section .accordion-group{
  width: 100%;
  
  color: var(--color-main);
}
@media (min-width: 40rem) {
  .faq-section .accordion-group{
    width: 50%;
    
  }
  .faq-section .container h1{
   
    font-size: 40px;
  }
}
