header{
  background: hsla(33, 82%, 64%, 1);

  background: radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  background: -moz-radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  background: -webkit-radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEAA57", endColorstr="#90DC0B", GradientType=1 ); 
}
.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  padding: 1.5rem 0;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}
.banner .container {
  padding: 1rem 1rem;
}
.banner .header-main-text {
  width: 100%;
  padding: 1rem 1rem;
}
.banner .header-main-text img {
  width: 100%;
}
.header-main-text .header-texts {
  border-radius: 10px;
  background: var(--color-theme-white);
  padding: 8rem 1rem;
}
.banner .header-main-text .heading-seperator {
  font-size: 2.95rem;
}
.banner .header-main-text h1 {
  font-size: 1.5rem;
  color: #ea8000;
  font-weight: 700;
}
.banner .banner-image {
  padding: 1rem;
  width: 100%;
  display: none;
  margin-top: -9rem;
}
.banner .banner-image img {
  width: 100%;
}
.banner .icon-google-play a {
  display: block;
  width: 250px;
  margin-right: 0.65rem;
}
.banner .icon-google-play {
  display: flex;
  gap: 1rem;
}
.banner .header-main-text .header-sub-text {
  font-size: 20px;
  padding: 1rem 0;
  width: 100%;
  color: var(--color-theme-black);
  line-height: 1.5;
  font-weight: 400;
}

.banner .header-main-text .icon-google-play img {
  width: 100%;
}

.first-image-line {
  position: relative;
  transform: translate(0px, 100px);
  margin-right: 2rem;
}
.first-border-line {
  position: absolute;
  transform: rotate(90deg);
  z-index: -1;
  bottom: -20%;
  left: 60%;
}
.second-image-line {
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 2rem;
  padding-right: 2rem;
}
@media (min-width: 60rem) {
  .banner {
    flex-direction: row;
    gap: 15rem;
    padding: 4rem 0;
  }

  .banner .header-main-text {
    width: 40%;
  }

  .banner .header-main-text .header-sub-text {
    font-size: 18px;
    line-height: 1.5;
  }
  .header-main-text .header-texts {
    padding: 2rem;
  }
  .banner .banner-image {
    width: 30%;
    display: initial;
  }
}
