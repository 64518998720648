:root {
  --color-main: #25c750ec;
  --color-secondary: #8abe1e;
  --color-gradient: linear-gradient(
    var(--gradient-direction),
    var(--color-main),
    var(--color-secondary)
  );
  --color-gradient2: linear-gradient(
    90deg,
    var(--color-main),
    var(--color-secondary)
  );
  --color-gradient3: radial-gradient(var(--color-main), var(--color-secondary));
  --color-main-light: #5be7c4;
  --color-theme-black: #212121;
  --color-black-light: #21212133;
  --color-gray: #637282;
  --color-gray-light: #ececec;
  --color-danger: #de350b;
  --color-theme-white: #fff;
  --color-theme-white-dark: #f6f6f6;
  --gradient-direction: to left;
  --transition-500: transform 500ms ease-in-out !important;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  position: relative;
}
header {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
main {
  min-height: 100vh;
}
.container {
  max-width: 80rem;
  margin: auto;
  overflow-x: hidden;
  padding: 0 2rem;
}

.btn {
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background: #de350b;
}
.btn-primary:hover,
.btn-primary:active {
  outline: none;
  background: #de350bcc;
  opacity: 0.9;
  border: none;
}
.subscribe-btn {
  font-size: 18px;
  color: #fff;
}
a.btn {
  border: none;
}
@media (min-width: 40rem) {
  .subscribe-btn {
    font-size: 24px;
  }
}

