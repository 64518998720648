.card-slider {
  background: var(--color-theme-white);
}
.card-slider .container {
  padding: 54px 2rem;
}
.card-slider-heading:after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-main);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.card-slider-heading {
  padding: 1.5rem;
  color: var(--color-main);
  text-align: center;
}
.card-slider-heading h1 {
  margin: 1rem 0;
  font-size: 2rem;
}
.service-card {
  width: 100% !important;
  background: var(--color-theme-white) !important;
  border-radius: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  transition: var(--transition-500);
  outline: none;
}
.service-card:hover {
  transform: translateY(-10px) ;
}

.service-card img {
  width: 100%;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}
.service-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.service-card-content a.btn span {
  display: inline-block;
  transform-origin: center;
}
.service-card-content a.btn svg {
  padding: 0.6rem 0 0rem 0;
  transform: translateY(-20px) scale(-1);
  visibility: hidden;
  transition: var(--transition-500);
  position: absolute;
  top: -20%;
  right: 36%;
}
.service-card-content a.btn span:first-child {
  transition: var(--transition-500);
  color: #fff;
}
.service-card-content a.btn {
  text-decoration: none;
  display: grid;
  text-align: center;
  background-color: var(--color-danger);
  margin: 0.9rem 2rem;
  padding: 0.3rem 1rem;
  border: 2px solid var(--color-danger);
  position: relative;
  transition: var(--transition-500) !important;
}
.service-card-content a.btn:hover {
  background: var(--color-danger);
  color: var(--color-theme-white);
}
.service-card-content a.btn:hover svg {
  transform: translateY(0px) scale(1);
  visibility: visible;
}
.service-card-content a.btn:hover span:first-child{
  transform: translateY(-20px) scale(-1);
  
  visibility: hidden;
}
.service-card-title {
  font-size: 1.093rem;
  color: var(--color-main);
  font-weight: 400;
}
.slick-slide {
  margin: 10px;
}
.slick-slider {
  width: 100%;
  background-color: transparent;
}
.slick-track {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: auto;
  justify-content: center;
}

.slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0px;
  margin: 10px;
  transition: all 0.3s ease;
  height: auto;
  text-align: center;
}

@media (min-width: 48rem) {
  .card-slider-heading h1 {
    font-size: 2.5rem;
  }
  .service-card {
    width: 350px !important;
  }
}
