.feature-section {
  background: hsla(33, 82%, 64%, 1);

  background: radial-gradient(
    circle,
    hsla(33, 82%, 64%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(33, 82%, 64%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(33, 82%, 64%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEAA57", endColorstr="#90DC0B", GradientType=1 );
}
.feature-section .container {
  padding: 54px 0;
  margin: auto;
  width: 80%;
}
.feature-section h1,
.feature-section p {
  line-height: 1.5;
  color: var(--color-theme-white);
}
.feature-section h1 {
  font-size: 2.5rem;
}
.feature-section-heading {
  padding: 2rem 0;
  text-align: center;
}
.feature-section-heading::after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.feature-section .features-list {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  justify-content: center;
}
.feature-section .features-list .group-one,
.feature-section .features-list .group-two {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature-section .features-list p {
  color: var(--color-theme-white);
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}
.feature-section .features-list .card {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.feature-section .features-list .card .card-icons {
  padding: 1rem;
  background: var(--color-danger);
  color: #fff;
}

@media (min-width: 60rem) {
  .feature-section .features-list {
    flex-direction: row;
  }
}
