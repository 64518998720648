.promote-view {
  background: var(--color-theme-white);
  margin: 0;
  color: var(--color-main);
  padding: 54px 0;
}
.promote-view .container {
  display: flex;
  flex-direction: column-reverse;
  padding:2rem 2rem;
  align-items: center;
  gap: 2rem;
}
.promote-view .promote-text {
  width: 100%;
  margin-top: 2rem;
  line-height: 1.5;
  

}
.promote-view .promote-text h1 {
  font-size: 30px;
  padding: 1 rem 0;
  color: var(--color-main);
  margin-bottom: 1rem;
}
.promote-view .promote-text p {
  line-height: 1.5;
  padding: 1rem 0;
  font-size: 18px;
}
.promote-view .promote-image img{
  width: 100%;
  
}

@media (min-width: 48rem) {
  .promote-view .container {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .promote-view .promote-text {
    width: 50%;
    margin-top: 0;
    font-size: 18px;
    margin-left: 1rem
  }
  .promote-view .promote-text h1{
    font-size: 2.5rem;

  }
}

@media (min-width: 40rem) {
  /* .promote-view .container {
    
  }
  .promote-view .promote-text {
  
  }
  .promote-view .promote-text h1{
   

  } */
}
