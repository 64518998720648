.how-it-works-view {
  margin: 0;
  background: hsla(34, 95%, 62%, 1);

  background: linear-gradient(
    180deg,
    hsla(34, 95%, 62%, 1) 10%,
    hsla(108, 91%, 65%, 1) 100%
  );

  background: -moz-linear-gradient(
    180deg,
    hsla(34, 95%, 62%, 1) 10%,
    hsla(108, 91%, 65%, 1) 100%
  );

  background: -webkit-linear-gradient(
    180deg,
    hsla(34, 95%, 62%, 1) 10%,
    hsla(108, 91%, 65%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FAAB43", endColorstr="#77F757", GradientType=1 );
}
.how-it-works-view .container {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}
.how-it-works-view {
  width: 100%;
}

.how-it-works-view h1 {
  font-size: 2.5rem;
  padding: 3rem 0;
  color: var(--color-theme-white);
  text-align: center;
}
.how-it-works-view h1:after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.how-it-works-view .step_cards {
  margin-top: 1.5rem;
}
.how-it-works-view .step_card {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.how-it-works-view .step_card .step_card-count {
  display: inline-block;
  font-size: 18px;
  background: #d33008;
  padding: 0.56rem;
  color: #fff;
  font-weight: 700;
  margin-right: 1rem;
}
.how-it-works-view .step_card p {
  line-height: 1.5;
  padding: 16px 0;
  color: var(--color-theme-white);
  font-size: 16px;
}
.how-it-works-details .image {
  width: 100%;
}
.how-it-works-details div img {
  width: 100%;
}
@media (min-width: 40rem) {
  .how-it-works-view .how-it-works-text h1 {
    font-size: 2.5rem;
  }
  .how-it-works-view .how-it-works-details {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3rem;
  }
  .how-it-works-details .image {
    width: 50%;
  }
  .how-it-works-view .step_cards {
    margin-top: 0;
    margin-left: 1.5rem;
  }
  .how-it-works-view .step_card p {
    font-size: 20px;
  }
  .how-it-works-view .step_card .step_card-count {
    padding: 1.5rem;
  }
}
