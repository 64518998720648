@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400&family=Poppins:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
body{
  font-family: 'Nunito Sans', sans-serif;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Poppins', sans-serif !important;
}
.promote-view {
  background: var(--color-theme-white);
  margin: 0;
  color: var(--color-main);
  padding: 54px 0;
}
.promote-view .container {
  display: flex;
  flex-direction: column-reverse;
  padding:2rem 2rem;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.promote-view .promote-text {
  width: 100%;
  margin-top: 2rem;
  line-height: 1.5;
  

}
.promote-view .promote-text h1 {
  font-size: 30px;
  padding: 1 rem 0;
  color: var(--color-main);
  margin-bottom: 1rem;
}
.promote-view .promote-text p {
  line-height: 1.5;
  padding: 1rem 0;
  font-size: 18px;
}
.promote-view .promote-image img{
  width: 100%;
  
}

@media (min-width: 48rem) {
  .promote-view .container {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .promote-view .promote-text {
    width: 50%;
    margin-top: 0;
    font-size: 18px;
    margin-left: 1rem
  }
  .promote-view .promote-text h1{
    font-size: 2.5rem;

  }
}

@media (min-width: 40rem) {
  /* .promote-view .container {
    
  }
  .promote-view .promote-text {
  
  }
  .promote-view .promote-text h1{
   

  } */
}

.earn-view {
  background: var(--color-theme-white-dark);
  margin: 0;
}
.earn-view .container {
  display: flex;
  flex-direction: /*row*/ column-reverse;
   align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2rem;
}
.earn-view .earn-text {
  width: 100%;
  margin-top: 1.5rem;
  line-height: 1.5;
}
.earn-view .earn-text h1 {
  font-size: 35px;
  padding: 1rem 0;
  color: var(--color-main)
}
.earn-view .earn-text h5 {
  margin-bottom: 0.5rem;
}

.earn-view .earn-text p {
  line-height: 1.5;
  padding: 1rem 0;
  color: var(--color-main);
  font-size: 16px;
}
.earn-view .earn-image img{
  width: 100%;
}
@media (min-width: 48rem) {
  .earn-view .container {
    flex-direction: row;
    justify-content: center;
  }
  .earn-view .earn-text {
      margin-top: 0;
      width: 50%;
  }
  .earn-view .earn-text p {
    width: 80%;
    font-size: 20px;
    
  }
  .earn-view .earn-text h1 {
    font-size: 3rem;
   
  }
}

.how-it-works-view {
  margin: 0;
  background: hsla(34, 95%, 62%, 1);

  background: linear-gradient(
    180deg,
    hsla(34, 95%, 62%, 1) 10%,
    hsla(108, 91%, 65%, 1) 100%
  );

  background: -webkit-linear-gradient(
    180deg,
    hsla(34, 95%, 62%, 1) 10%,
    hsla(108, 91%, 65%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FAAB43", endColorstr="#77F757", GradientType=1 );
}
.how-it-works-view .container {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}
.how-it-works-view {
  width: 100%;
}

.how-it-works-view h1 {
  font-size: 2.5rem;
  padding: 3rem 0;
  color: var(--color-theme-white);
  text-align: center;
}
.how-it-works-view h1:after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.how-it-works-view .step_cards {
  margin-top: 1.5rem;
}
.how-it-works-view .step_card {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
}
.how-it-works-view .step_card .step_card-count {
  display: inline-block;
  font-size: 18px;
  background: #d33008;
  padding: 0.56rem;
  color: #fff;
  font-weight: 700;
  margin-right: 1rem;
}
.how-it-works-view .step_card p {
  line-height: 1.5;
  padding: 16px 0;
  color: var(--color-theme-white);
  font-size: 16px;
}
.how-it-works-details .image {
  width: 100%;
}
.how-it-works-details div img {
  width: 100%;
}
@media (min-width: 40rem) {
  .how-it-works-view .how-it-works-text h1 {
    font-size: 2.5rem;
  }
  .how-it-works-view .how-it-works-details {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .how-it-works-details .image {
    width: 50%;
  }
  .how-it-works-view .step_cards {
    margin-top: 0;
    margin-left: 1.5rem;
  }
  .how-it-works-view .step_card p {
    font-size: 20px;
  }
  .how-it-works-view .step_card .step_card-count {
    padding: 1.5rem;
  }
}

header{
  background: hsla(33, 82%, 64%, 1);

  background: radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  background: -webkit-radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEAA57", endColorstr="#90DC0B", GradientType=1 ); 
}
.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  height: 100%;
  padding: 1.5rem 0;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}
.banner .container {
  padding: 1rem 1rem;
}
.banner .header-main-text {
  width: 100%;
  padding: 1rem 1rem;
}
.banner .header-main-text img {
  width: 100%;
}
.header-main-text .header-texts {
  border-radius: 10px;
  background: var(--color-theme-white);
  padding: 8rem 1rem;
}
.banner .header-main-text .heading-seperator {
  font-size: 2.95rem;
}
.banner .header-main-text h1 {
  font-size: 1.5rem;
  color: #ea8000;
  font-weight: 700;
}
.banner .banner-image {
  padding: 1rem;
  width: 100%;
  display: none;
  margin-top: -9rem;
}
.banner .banner-image img {
  width: 100%;
}
.banner .icon-google-play a {
  display: block;
  width: 250px;
  margin-right: 0.65rem;
}
.banner .icon-google-play {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.banner .header-main-text .header-sub-text {
  font-size: 20px;
  padding: 1rem 0;
  width: 100%;
  color: var(--color-theme-black);
  line-height: 1.5;
  font-weight: 400;
}

.banner .header-main-text .icon-google-play img {
  width: 100%;
}

.first-image-line {
  position: relative;
  transform: translate(0px, 100px);
  margin-right: 2rem;
}
.first-border-line {
  position: absolute;
  transform: rotate(90deg);
  z-index: -1;
  bottom: -20%;
  left: 60%;
}
.second-image-line {
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 2rem;
  padding-right: 2rem;
}
@media (min-width: 60rem) {
  .banner {
    flex-direction: row;
    grid-gap: 15rem;
    gap: 15rem;
    padding: 4rem 0;
  }

  .banner .header-main-text {
    width: 40%;
  }

  .banner .header-main-text .header-sub-text {
    font-size: 18px;
    line-height: 1.5;
  }
  .header-main-text .header-texts {
    padding: 2rem;
  }
  .banner .banner-image {
    width: 30%;
    display: inline;
    display: initial;
  }
}

.nav-wrapper {
  background: transparent;

  width: 100%;
}

.nav-wrapper .nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1rem 1.2rem;
  transition: all 2000ms ease-in-out !important;
}
.nav-wrapper .nav .nav-brand a img {
  height: 60px;
  display: none;
}

.nav-wrapper .nav .nav-menu-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: hsla(33, 83%, 61%, 1);

  background: linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
  bottom: 100%;
  top: 0;
  z-index: 10;
  right: 0;
  list-style-type: none;
  align-items: center;
  transform: translateX(-800px);
  transition: all 0.5s;
}
.nav-wrapper .nav .nav-menu-group.mobile {
  transform: translateX(0px);
}

.nav-wrapper .nav .nav-menu-group .nav-menu-list {
  margin-right: 1.66rem;
  font-size: 1rem;
  font-weight: 400;
}

.bars {
  cursor: pointer;
  z-index: 999;
}
.bars.clicked .line:nth-child(2) {
  display: none;
}
.bars.clicked .line:nth-child(1) {
  transform: rotate(140deg) translateY(-8px);
}
.bars.clicked .line:nth-child(3) {
  transform: rotate(-140deg) translateY(10px);
}
.bars .line {
  transition: all 500ms;
  height: 3px;
  width: 45px;
  display: block;
  background: #fff;
  margin-bottom: 0.78rem;
  border-radius: 5px;
}
@media (min-width: 60rem) {
  .nav-wrapper {
    background-position: top;
  }
  .nav-wrapper .nav {
    padding: 1rem;
  }
  .nav-wrapper .nav .nav-menu-group {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    position: static;
    height: auto;
    width: auto;
    background: none;
    align-items: center;
    transform: translateX(0px);
    transition: all 0.6s;
  }
  .nav-wrapper .nav .nav-menu-group a {
    font-size: 18px;
    margin-bottom: 0;
  }
  .bars {
    display: none;
  }
}

.unique__feature {
  background: hsla(33, 83%, 61%, 1);

  background: linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
  padding: 54px 0;
}

.unique__feature-heading {
  padding: 2rem 0;
  text-align: center;
}
.unique__feature-heading:after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.unique__feature-heading h1 {
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-theme-white);
}
.unique__feature-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.unique__feature-cards__card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: justify;
  font-size: 16px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  background: var(--color-theme-white);
  color: var(--color-main);
  border-radius: 10px;
  transition: all 600ms ease !important;
  overflow: hidden;
}

.unique__feature-card-content {
  padding: 20px 30px;
  overflow: hidden;
}
.unique__feature-cards__card img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.unique__feature-cards__card:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.281);
  transform: translateY(-5px);
  outline: none;
}

.unique__feature-cards__card .title {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
}

.unique__feature-cards__card h3 {
  margin: 1rem 0;
  text-align: center;
}

@media (min-width: 60rem) {
  .unique__feature-cards {
    flex-direction: row;
  }
  .unique__feature-cards__card {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    width: 350px !important;
  }
  .unique__feature .unique__feature-heading h1 {
    font-size: 2.5rem;
  }
}

.feature-section {
  background: hsla(33, 82%, 64%, 1);

  background: radial-gradient(
    circle,
    hsla(33, 82%, 64%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(33, 82%, 64%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEAA57", endColorstr="#90DC0B", GradientType=1 );
}
.feature-section .container {
  padding: 54px 0;
  margin: auto;
  width: 80%;
}
.feature-section h1,
.feature-section p {
  line-height: 1.5;
  color: var(--color-theme-white);
}
.feature-section h1 {
  font-size: 2.5rem;
}
.feature-section-heading {
  padding: 2rem 0;
  text-align: center;
}
.feature-section-heading::after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.feature-section .features-list {
  display: flex;
  flex-direction: column-reverse;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}
.feature-section .features-list .group-one,
.feature-section .features-list .group-two {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.feature-section .features-list p {
  color: var(--color-theme-white);
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}
.feature-section .features-list .card {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}
.feature-section .features-list .card .card-icons {
  padding: 1rem;
  background: var(--color-danger);
  color: #fff;
}

@media (min-width: 60rem) {
  .feature-section .features-list {
    flex-direction: row;
  }
}

.ad-video {
    background: var(--color-theme-white);
    padding: 2rem 0;
}
.ad-video .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  overflow: hidden;
  padding: 1rem 2rem;
  /* height: 100vh; */
}
.ad-video .container .player-image {
  width: 40%;
}
.ad-video .container .player-image img {
  width: 100%;
}
.react-player {
  -webkit-filter: drop-shadow(3px 10px 9px #d30700a4);
          filter: drop-shadow(3px 10px 9px #d30700a4);
  width: 343px !important;
  height: 250px !important;
  border-radius: 5px;
}
.react-player video {
  width: 100%;
  height: 100% !important;
  /* display: none; */
}
@media (min-width: 48rem) {
  .ad-video .container {
    height: auto;
    flex-direction: row;
    grid-gap: 5rem;
    gap: 5rem;
  }
  .ad-video .container .player-image {
    width: 10%;
  }
  .react-player {
    width: 343px !important;
  }
  .react-player video {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.card-slider {
  background: var(--color-theme-white);
}
.card-slider .container {
  padding: 54px 2rem;
}
.card-slider-heading:after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-main);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.card-slider-heading {
  padding: 1.5rem;
  color: var(--color-main);
  text-align: center;
}
.card-slider-heading h1 {
  margin: 1rem 0;
  font-size: 2rem;
}
.service-card {
  width: 100% !important;
  background: var(--color-theme-white) !important;
  border-radius: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  transition: var(--transition-500);
  outline: none;
}
.service-card:hover {
  transform: translateY(-10px) ;
}

.service-card img {
  width: 100%;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}
.service-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.service-card-content a.btn span {
  display: inline-block;
  transform-origin: center;
}
.service-card-content a.btn svg {
  padding: 0.6rem 0 0rem 0;
  transform: translateY(-20px) scale(-1);
  visibility: hidden;
  transition: var(--transition-500);
  position: absolute;
  top: -20%;
  right: 36%;
}
.service-card-content a.btn span:first-child {
  transition: var(--transition-500);
  color: #fff;
}
.service-card-content a.btn {
  text-decoration: none;
  display: grid;
  text-align: center;
  background-color: var(--color-danger);
  margin: 0.9rem 2rem;
  padding: 0.3rem 1rem;
  border: 2px solid var(--color-danger);
  position: relative;
  transition: var(--transition-500) !important;
}
.service-card-content a.btn:hover {
  background: var(--color-danger);
  color: var(--color-theme-white);
}
.service-card-content a.btn:hover svg {
  transform: translateY(0px) scale(1);
  visibility: visible;
}
.service-card-content a.btn:hover span:first-child{
  transform: translateY(-20px) scale(-1);
  
  visibility: hidden;
}
.service-card-title {
  font-size: 1.093rem;
  color: var(--color-main);
  font-weight: 400;
}
.slick-slide {
  margin: 10px;
}
.slick-slider {
  width: 100%;
  background-color: transparent;
}
.slick-track {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: auto;
  justify-content: center;
}

.slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0px;
  margin: 10px;
  transition: all 0.3s ease;
  height: auto;
  text-align: center;
}

@media (min-width: 48rem) {
  .card-slider-heading h1 {
    font-size: 2.5rem;
  }
  .service-card {
    width: 350px !important;
  }
}

/* Style the accordion section */
.accordion {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
 outline: none;
 transition: all 0.6s ease;
 /* border: var(--color-main) 2px solid; */
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion_ {
  background-color: var(--color-theme-white-dark);
  color: var(--color-main);
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.5s ease;
  box-shadow: 1px 1px 166px -88px rgba(0,0,0,0.52);
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: var(--color-theme-white);
  outline: none;
}

/* Style the accordion content title */
.accordion-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion-icon {
  margin-left: auto;
  transition: transform 0.5s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.faq-section{
  padding: 2rem 0;
  /* background: whitesmoke; */
  background: hsla(33, 83%, 61%, 1);

background: linear-gradient(315deg, hsla(33, 83%, 61%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);

background: -webkit-linear-gradient(315deg, hsla(33, 83%, 61%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
}
.faq-section .container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faq-section .container h1{
  padding: 2rem 0;
  color: var(--color-theme-white);
  font-size: 30px;
  text-align: center
}
.faq-section .accordion-group .accordion-text{
  line-height: 1.5
}
.faq-section .accordion-group{
  width: 100%;
  
  color: var(--color-main);
}
@media (min-width: 40rem) {
  .faq-section .accordion-group{
    width: 50%;
    
  }
  .faq-section .container h1{
   
    font-size: 40px;
  }
}

.app-feature-section {
  padding: 2.5rem 0 2.5rem 0;
  overflow: hidden;
}
.app-feature-section h1,
.app-feature-section p {
  color: var(--color-main);
}
.app-feature-section h1 {
  font-size: 2.5rem;
}
.app-feature-heading {
  padding: 2rem 0;
  text-align: center;
}
.app-feature-heading::after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.feature-wrapper {
  /* display: flex;
  /* gap: 1rem; */
  padding: 2rem 0;
  flex-direction: column;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: none;
  grid-gap: 1rem;
  gap: 1rem;
  
}

.feature-wrapper .group-one,
.feature-wrapper .group-two {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.feature-card p {
  color: var(--color-theme-white);
}
.feature-wrapper .feature-card {
  background: hsla(33, 83%, 61%, 1);

  background: linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.164);
  height: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
 
}

.feature-card-title p {
  padding: 1rem;
  line-height: 1.5;
}

@media (min-width: 48rem) {
  .app-feature-section .feature-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: none;
  }
  .app-feature-section .feature-wrapper .feature-card .feature-card-icons {
    padding: 0.65rem;
  }
  .feature-card-title p {
    padding: 1rem;
  }
}

.become-a-provider-view {
    background: var(--color-theme-white);
    margin: 0;
   
  }
  .become-a-provider-view .container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
  }
  .become-a-provider-view {
    width: 100%;
  }
  
  .become-a-provider-view h1 {
    font-size: 2.5rem;
    padding: 3rem 0;
    color: var(--color-main);
    text-align: center;
  }
  .become-a-provider-view h1:after{
    display: block;
    content: "";
    width: 120px;
    height: 5px;
    background: var(--color-danger);
    border-radius: 5px;
    display: flex;
   margin:1rem  auto;
  }
  .become-a-provider-view .step_cards{
    margin-top: 1.5rem;
    
  }
  .become-a-provider-view .step_card {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .become-a-provider-view .step_card .step_card-count{
    display: inline-block;
    font-size: 24px;
    background: #d33008;
    padding: 1rem;
    color: var(--color-theme-white);
    font-weight: 700;
    margin-right: 1rem;
  }
  .become-a-provider-view .step_card p {
    line-height: 1.5;
    padding: 16px 0;
    color: var(--color-main);
    font-size: 16px;
  }
  .become-a-provider-view  .how-it-works-details .image {
    width: 100%;
  }
  .become-a-provider-view  .how-it-works-details div img {
    width: 100%;
  }
  @media (min-width: 40rem) {
    .become-a-provider-view .how-it-works-text h1 {
      font-size: 2.5rem;
    }
    .become-a-provider-view .how-it-works-details {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      grid-gap: 3rem;
      gap: 3rem;
    }
    .become-a-provider-view .how-it-works-details .image {
      width: 50%;
    }
    .become-a-provider-view .step_cards{
      margin-top: 0;
      margin-left: 1.5rem;
    }
    .become-a-provider-view .step_card p {
      font-size: 20px;
    }
    .become-a-provider-view .step_card .step_card-count{
      padding: 1.5rem;
    }
    
  }
  
:root {
  --color-main: #25c750ec;
  --color-secondary: #8abe1e;
  --color-gradient: linear-gradient(
    var(--gradient-direction),
    var(--color-main),
    var(--color-secondary)
  );
  --color-gradient2: linear-gradient(
    90deg,
    var(--color-main),
    var(--color-secondary)
  );
  --color-gradient3: radial-gradient(var(--color-main), var(--color-secondary));
  --color-main-light: #5be7c4;
  --color-theme-black: #212121;
  --color-black-light: #21212133;
  --color-gray: #637282;
  --color-gray-light: #ececec;
  --color-danger: #de350b;
  --color-theme-white: #fff;
  --color-theme-white-dark: #f6f6f6;
  --gradient-direction: to left;
  --transition-500: transform 500ms ease-in-out !important;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  position: relative;
}
header {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
main {
  min-height: 100vh;
}
.container {
  max-width: 80rem;
  margin: auto;
  overflow-x: hidden;
  padding: 0 2rem;
}

.btn {
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background: #de350b;
}
.btn-primary:hover,
.btn-primary:active {
  outline: none;
  background: #de350bcc;
  opacity: 0.9;
  border: none;
}
.subscribe-btn {
  font-size: 18px;
  color: #fff;
}
a.btn {
  border: none;
}
@media (min-width: 40rem) {
  .subscribe-btn {
    font-size: 24px;
  }
}


.footer-content {
  
  background: hsla(33, 82%, 64%, 1);

  background: radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  background: -webkit-radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEAA57", endColorstr="#90DC0B", GradientType=1 ); 
  color: var(--color-theme-white);
}
.footer-content .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 2rem;
  flex-wrap: wrap;
}
.footer-content .container h1{
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--color-theme-white);
  text-align: center;
  width: 100%;
}
.footer-content .container ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: center;
  align-items: flex-start;
}
.footer-content .container ul li {
  margin-bottom: 1rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
}

.footer-content .container ul li span.icon-container {
  display: inline-block;
  margin-right: 0.9rem;
}
.footer-content .container ul li span {
  background: var(--color-danger);
  display: inline-block;
  padding: 1rem;
  margin: 0.5rem;
}

.footer-label {
  text-align: center;
  padding: 1.5rem;
}
.footer__icons {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.footer__icons img {
  margin-right: 1rem;
}

@media (min-width: 40rem) {
  .footer-content .container {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-content .container ul {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  .footer-content .container ul li {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 850px) {
  .footer-content .container {
    flex-wrap: wrap;
  }
}

