.become-a-provider-view {
    background: var(--color-theme-white);
    margin: 0;
   
  }
  .become-a-provider-view .container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
  }
  .become-a-provider-view {
    width: 100%;
  }
  
  .become-a-provider-view h1 {
    font-size: 2.5rem;
    padding: 3rem 0;
    color: var(--color-main);
    text-align: center;
  }
  .become-a-provider-view h1:after{
    display: block;
    content: "";
    width: 120px;
    height: 5px;
    background: var(--color-danger);
    border-radius: 5px;
    display: flex;
   margin:1rem  auto;
  }
  .become-a-provider-view .step_cards{
    margin-top: 1.5rem;
    
  }
  .become-a-provider-view .step_card {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .become-a-provider-view .step_card .step_card-count{
    display: inline-block;
    font-size: 24px;
    background: #d33008;
    padding: 1rem;
    color: var(--color-theme-white);
    font-weight: 700;
    margin-right: 1rem;
  }
  .become-a-provider-view .step_card p {
    line-height: 1.5;
    padding: 16px 0;
    color: var(--color-main);
    font-size: 16px;
  }
  .become-a-provider-view  .how-it-works-details .image {
    width: 100%;
  }
  .become-a-provider-view  .how-it-works-details div img {
    width: 100%;
  }
  @media (min-width: 40rem) {
    .become-a-provider-view .how-it-works-text h1 {
      font-size: 2.5rem;
    }
    .become-a-provider-view .how-it-works-details {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 3rem;
    }
    .become-a-provider-view .how-it-works-details .image {
      width: 50%;
    }
    .become-a-provider-view .step_cards{
      margin-top: 0;
      margin-left: 1.5rem;
    }
    .become-a-provider-view .step_card p {
      font-size: 20px;
    }
    .become-a-provider-view .step_card .step_card-count{
      padding: 1.5rem;
    }
    
  }
  