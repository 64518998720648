.footer-content {
  
  background: hsla(33, 82%, 64%, 1);

  background: radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  background: -moz-radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  background: -webkit-radial-gradient(circle, hsla(33, 82%, 64%, 1) 10%, hsla(82, 90%, 45%, 1) 86%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEAA57", endColorstr="#90DC0B", GradientType=1 ); 
  color: var(--color-theme-white);
}
.footer-content .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 2rem;
  flex-wrap: wrap;
}
.footer-content .container h1{
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--color-theme-white);
  text-align: center;
  width: 100%;
}
.footer-content .container ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: center;
  align-items: flex-start;
}
.footer-content .container ul li {
  margin-bottom: 1rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
}

.footer-content .container ul li span.icon-container {
  display: inline-block;
  margin-right: 0.9rem;
}
.footer-content .container ul li span {
  background: var(--color-danger);
  display: inline-block;
  padding: 1rem;
  margin: 0.5rem;
}

.footer-label {
  text-align: center;
  padding: 1.5rem;
}
.footer__icons {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.footer__icons img {
  margin-right: 1rem;
}

@media (min-width: 40rem) {
  .footer-content .container {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-content .container ul {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  .footer-content .container ul li {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 850px) {
  .footer-content .container {
    flex-wrap: wrap;
  }
}
