.app-feature-section {
  padding: 2.5rem 0 2.5rem 0;
  overflow: hidden;
}
.app-feature-section h1,
.app-feature-section p {
  color: var(--color-main);
}
.app-feature-section h1 {
  font-size: 2.5rem;
}
.app-feature-heading {
  padding: 2rem 0;
  text-align: center;
}
.app-feature-heading::after {
  display: block;
  content: "";
  width: 120px;
  height: 5px;
  background: var(--color-danger);
  border-radius: 5px;
  display: flex;
  margin: 1rem auto;
}
.feature-wrapper {
  /* display: flex;
  /* gap: 1rem; */
  padding: 2rem 0;
  flex-direction: column;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: none;
  gap: 1rem;
  
}

.feature-wrapper .group-one,
.feature-wrapper .group-two {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.feature-card p {
  color: var(--color-theme-white);
}
.feature-wrapper .feature-card {
  background: hsla(33, 83%, 61%, 1);

  background: linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -moz-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(33, 83%, 61%, 1) 10%,
    hsla(82, 90%, 45%, 1) 86%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EEA44A", endColorstr="#90DC0B", GradientType=1 );
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.164);
  height: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
 
}

.feature-card-title p {
  padding: 1rem;
  line-height: 1.5;
}

@media (min-width: 48rem) {
  .app-feature-section .feature-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: none;
  }
  .app-feature-section .feature-wrapper .feature-card .feature-card-icons {
    padding: 0.65rem;
  }
  .feature-card-title p {
    padding: 1rem;
  }
}
